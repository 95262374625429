import React from "react";
import { Container } from "reactstrap";
import houseView from "../../assets/img/houseView.png";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Form,
  Label,
  Input,
  Modal,
  ModalBody,
  Button,
  Spinner,
} from "reactstrap";
import Logo from "../../assets/img/secondYntro.png";

import Register from "./Register";

function Header() {
  return (
    <div className="container-fluid headerSection">
      <Row>
        <div className="headerSection__topDiv">
          {/* <h1 className="text-center my-2">Yntro</h1> */}
          <div className="text-center">
            <img
              src={Logo}
              alt="logo"
              color="#e5ec26"
              className="mx-auto my-2"
            />
          </div>
          <div className="headerSection__topDiv--yellowDiv mb-1 py-3">
            <b className="FW--600">UNIEKE KANS!</b>
            <br />
            <span className="fw--500 ml-md-2 pb-3">
              Laatste 2 hotelkamers beschikbaar!{" "}
            </span>
          </div>
          <div className="headerSection__topDiv--fonts">
            <div className="d-flex  my-2 mr-1">
              <i className="fa-solid fa-check "></i>
              <div className="text-">
                <b className="fw--700">Recent gerenoveerd. </b>
              </div>
            </div>
            <div className="d-flex  my-2 mr-1">
              <i className="fa-solid fa-check "></i>
              <div className="text-">
                <b className="fw--700"> Toplocatie</b>
                <span className="fw--500 ml-2">op </span>
                <b className="fw--700">slechts 750 meter van het strand.</b>
              </div>
            </div>
            <div className="d-flex my-2">
              <i className="fa-solid fa-check"></i>
              <div className="text-">
                <span className="fw--700 text-">
                  Kind- en gezinsvriendelijke faciliteiten
                </span>
                <span className="fw--500 ml-2">
                  waaronder een verwarmd buitenzwembad.
                </span>
              </div>
            </div>
            <div className="d-flex  my-2">
              <i className="fa-solid fa-check"></i>
              <div className="text-">
                <span className="fw--700 text-">
                  Geniet mee van alle opbrengsten
                </span>
                <span className="fw--500 ml-2">
                  van het hotel. Ook die van de vergaderzalen, de catering en de
                  bar.
                </span>
              </div>
            </div>
            <div className="d-flex my-2">
              <i className="fa-solid fa-check"></i>
              <div className="text-">
                <span className="fw--700 text-">
                  Combinatie eigen gebruik en professionele verhuur
                </span>
                <span className="fw--500 ml-2">
                  door beursgenoteerde groep Accor.
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center my-2">
              <i className="fa-solid fa-check"></i>
              <span className="fw--700 text-">
                Fiscaal gunstige aankoopformules
              </span>
            </div>
            {/* <div className="d-flex my-2">
              <i className="fa-solid fa-check "></i>

              <div className="text-">
                {" "}
                <b className="fw--700 ">
                  {" "}
                  Geniet mee van alle opbrengsten van het hotel.
                </b>
                <span className="fw--500 ml-2">
                  Ook die van de vergaderzalen, de catering en de bar.
                </span>
              </div>
            </div>

            <div className="d-flex mt-2 h-">
              <i className="fa-solid fa-check "></i>
              <b className="fw--700 pb-0">Voordelig zelf van genieten:</b>
            </div>
            <ul className="fw--500 text- mt-0 pb-0">
              <li className="ml-md-2 text-left pt-0">Verwarmd buitenzwembad</li>
              <li className="ml-md-2 text-left pt-0">
                Vlakbij het strand en de duinen
              </li>
              <li className="ml-md-2 text-left pt-0">Gezellige bar</li>
            </ul> */}
          </div>
        </div>
      </Row>
      <Row>
        <Register />
      </Row>
    </div>
  );
}

export default Header;
